<template>
  <div :class="{'default':classFlag}">

    <div
      class="box"
      id="imgBox"
    >
      <!-- 背景图 -->
      <img
        class="bg-img"
        :src="background_img"
        alt=""
        style="max-width:420px"
      >
      <!-- <div style="height:680px;background: linear-gradient(to left bottom,rgb(72, 124, 253), 57%, rgb(46, 79, 191));"></div> -->
      <!-- 头像昵称 -->
      <div
        class="profile"
        v-if="headimgurl"
      >
        <div class="headimgurl">
          <img
            :src="headimgurl"
            alt=""
          >
        </div>
        <div class="nickname">{{ nickname }}
        </div>
      </div>

      <!-- 主标题 -->
      <div
        :class="{'title-box':flag}"
        v-html="title"
      ></div>

      <!-- 副标题 -->
      <div
        :class="{'subhead-box':flag}"
        v-html="subTitle"
      ></div>

      <!-- 文案内容上 -->
      <div :class="{'content-box-top':flag}">
        <div
          class="content-item-box"
          v-for="(item,index) in contentList"
          :key="index"
          :class="[`content-item`,`content-item${index}`]"
        >
          <div :class="['content-item-icon',`content-item-icon${index}`]">
            <img
              v-if="item.icon"
              :src="item.icon"
              alt=""
            >
            <ul v-if="!item.icon">
              <li></li>
            </ul>
          </div>
          <div v-html="item.text"></div>
          <div
            v-if="index === 0 && item.title_icon"
            class="title-first-icon"
          >
            <img
              :src="item.title_icon"
              alt=""
            >
          </div>
        </div>
      </div>

      <!-- 底部说明左 -->
      <div :class="{'footer-box-left':flag}">
        <div
          v-for="(item,index) in footerList"
          :key="index"
          :class="['footerlist-item',`footerlist-item${index}`]"
          v-html="item.text"
        >

        </div>
      </div>
      <!-- 底部说明右 -->
      <div
        :class="{'footer-box-right':flag}"
        v-if="qrcode_url"
      >
        <VueQr
          :text="qrcode_url"
          :size="75"
          :margin="2"
        ></VueQr>
      </div>

    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from 'axios'
import VueQr from 'vue-qr'
export default {
  data() {
    return {
      loading: false,
      classFlag: true,
      flag: true,
      background_img: '',
      headimgurl: 'https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png',
      nickname: '霍格沃兹测试开发',
      qrcode_url: '',
      title: '',
      subTitle: '',
      contentList: [
      ],
      footerList: [
      ],
      cssAddress: '',
    }
  },
  components: {
    VueQr
  },
  created() {
    console.log(this.$toImage);
    this.getData()
  },
  mounted() {
    window.vue = this
  },
  methods: {
    //获取数据
    getData() {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.background_img = res.data.data.background_img
            this.title = res.data.data.title
            this.subTitle = res.data.data.subTitle
            this.contentList = res.data.data.contentList
            this.footerList = res.data.data.footerList
            this.headimgurl = res.data.data.headimgurl
            this.nickname = res.data.data.nickname
            this.qrcode_url = res.data.data.qrcode_url
            if (res.data.data.css) {
              this.createCssLink(res.data.data.css)
            } else {
              // this.createCssLink('default')
            }
          }

        })
    },

    //避免重复载入 
    removeRemoteCss() {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (let i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink(css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      // setTimeout(() => { this.domtoimg() }, 1500)
      // 待优化 同裂变系统一样 待二维码图片渲染完成后 立即执行canvas
      setTimeout(() => {
        this.$toImage.domtoimg(document.getElementById('imgBox'))
        this.loading = false
      }, 1500)

    },


    //图片加载监控
    imgload_() {
      // setTimeout(() => {

      //   let img = document.querySelector('.footer-box-right img')
      //   console.log(img);

      //   let imgList = document.getElementsByTagName('img');//图片集合
      //   console.log(imgList, '获取页面所有图片');

      //   let imgCount = imgList.length;//图片总数
      //   console.log(imgCount);
      //   let imgLoad = 0;//加载完成的图片数量
      //   for (let i = 0; i < imgCount; i++) {
      //     console.log(imgLoad);
      //     imgList[i].onload = () => {
      //       imgLoad++;
      //       console.log('测试图片加载判断方法执行');
      //       if (imgLoad === imgCount) {
      //         // img加载完成 do something
      //         console.log('图片全部加载完成');
      //         // this.saveHtml2Image()
      //       }
      //     }
      //   }
      // }, 0)

    },

  }
}
</script>

<style scoped lang="less">
// .content-item-icon {
//   width: 50px;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .content-item-icon0 {
//   display: none;
// }
// .content-item-icon img {
//   width: 30px;
//   overflow: hidden;
// }
// .title-first-icon {
//   width: 50px;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .title-first-icon img {
//   width: 30px;
//   overflow: hidden;
// }
// ul {
//   width: 100%;
//   list-style-position: inside;
//   margin-left: 20px;
// }
// .default {
//   display: flex;
//   justify-content: center;
// }
// .box {
//   position: relative;
//   display: inline-block;
//   line-height: 0;
// }
// /* 背景图 */
// .bg-img {
//   width: 100%;
//   object-fit: contain; /* 图片尺寸自适应 */
// }
// /* 头像昵称 */
// .profile {
//   display: flex;
//   justify-content: flex-start; /* 水平居中 */
//   align-items: center; /* 垂直居中 */
//   position: absolute;
//   top: 2%;
//   left: 5%;
// }
// /* 头像 */
// .headimgurl {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   overflow: hidden; /* 溢出隐藏 */
//   background-color: #fff; /* 背景色 */
// }
// /* 昵称 */
// .nickname {
//   color: #fff; /* 文字颜色 */
//   font-size: 16px; /* 文字大小 */
//   margin-left: 10px; /* 距离左边的距离 */
//   font-family: "Courier New", Courier, monospace; /* 字体设置 默认第一个(不生效,依次向后查找可用字体) */
// }
// .headimgurl img {
//   width: 100%;
//   height: 100%;
// }

// .title-box,
// .content-box-bottom,
// .footer-box-left,
// .footer-box-right,
// .content-box-top,
// .subhead-box {
//   position: absolute;
//   line-height: 1;
// }
// /* 主标题 */
// .title-box {
//   top: 15%;
//   width: 100%;
//   text-align: center; /* 文字水平居中 */
//   font-size: 48px;
//   font-weight: 700; /* 字体加粗 */
//   font-family: "Fantasy";
//   color: #fff;
// }
// /* 副标题 */
// .subhead-box {
//   top: 31.5%;
//   width: 100%;
//   text-align: center;
//   font-size: 15px;
//   padding: 0 20px;
//   color: #fff;
// }
// /* 中间主体内容 */
// .content-box-top {
//   top: 37.5%;
//   width: 100%;
//   letter-spacing: 1px; /* 文字左右间距 */
//   font-size: 16px;
//   font-family: "FangSong";
//   padding: 5px 15px;
//   border-radius: 40px; /* 圆角属性 */
//   overflow: hidden;
// }
// /* 中间主体内容里的每一行icon */
// .content-item-icon {
//   width: 40px;
//   height: 100%;
// }
// /* 中间主体内容里的第一行icon */
// .content-item-icon0 {
//   width: 0;
// }
// /* 中间主体内容里的每一行 */
// .content-item {
//   display: flex;
//   align-items: center;
//   border-bottom: 1px dotted #000; /* 下边框 粗细 边框样式 颜色 */
//   background-color: #fff;
//   height: 50px;
//   font-size: 18px;
//   color: #000;
// }
// /* 中间主体内容里的第一行 */
// .content-item0 {
//   justify-content: center;
//   text-indent: 0; /* 文字缩进 */
//   background: linear-gradient(to right, #e77463, 80%, #d5518e);
//   color: #fff;
//   font-size: 22px;
// }
// /* 二维码 */
// .footer-box-right {
//   line-height: 0;
//   top: 87%;
//   left: 74%;
// }
// /* 底部文字 */
// .footer-box-left {
//   width: 67%;
//   top: 88.5%;
//   left: 8%;
//   font-family: "Microsoft YaHei";
// }
// /* 底部文字每一行 */
// .footerlist-item {
//   display: flex;
//   align-items: center;
//   height: 30px;
//   font-size: 18px;
//   background: transparent;
//   color: #fff;
// }
</style>